import { useCallback, useMemo, useState } from "react";
import StudentTag from "../../domains/StudentTag";

export const useAnnounceDestinationTag = ({
  tags,
}: {
  tags: StudentTag[];
}) => {
  const [openedTagId, setOpenedTagId] = useState<string | null>(null);

  const closeAnnounceDestinationTag = useCallback(() => {
    setOpenedTagId(null);
  }, []);

  const openAnnounceDestinationTagById = useCallback(
    (id: string) => {
      const destinationTag = tags.find((tag) => tag.id === id);
      if (destinationTag) {
        setOpenedTagId(destinationTag.id);
      }
    },
    [tags],
  );

  const openedTag = useMemo(
    () => tags.find((tag) => tag.id === openedTagId) ?? null,
    [tags, openedTagId],
  );

  return {
    openedTag,
    openAnnounceDestinationTagById,
    closeAnnounceDestinationTag,
  };
};
