import { FormikProps, useFormikContext } from "formik";
import * as React from "react";
import { OnChangeValue } from "react-select";
import * as yup from "yup";
import { OptionType } from "../../../components/general/SelectWrapper";
import { Lecture } from "../../../domains/Lecture";
import {
  LectureQuestionnaire,
  LectureQuestionnaireFormat,
} from "../../../domains/LectureQuestionnaire";

// 二択の <input type="radio"> に直接boolean型のvalueを設定できなくて使いづらいのでフォーム内では "on" か "off"の文字列で管理する
type ToggleState = "on" | "off";

type QuestionnaireValue = Omit<LectureQuestionnaire, "id" | "choices"> & {
  id?: LectureQuestionnaire["id"];
  isInputting: boolean;
} & Required<Pick<LectureQuestionnaire, "choices">>;
export type LectureValue = Pick<Lecture, "name" | "attendanceConfirm"> & {
  id?: string;
  attendanceLocationCollect: ToggleState;
  attendanceLimitDays: number;
  questionnaires: QuestionnaireValue[];
};

const MAX_QUESTION_COUNT = 5;

// 講座の設定項目(アンケート以外)のフォームの準備をするヘルパーメソッド
export const prepareLectureConfig = (formik: FormikProps<LectureValue>) => {
  const lectureNameProps = {
    name: "name",
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    value: formik.values.name,
    hasError: Boolean(formik.errors.name && formik.touched.name),
    placeholder: "名称を入力",
  };

  const isAttendanceConfirmNone = formik.values.attendanceConfirm === "none";

  const getRadioGroupProps = <K extends keyof LectureValue>(
    key: K,
    value: LectureValue[K],
  ) => {
    return {
      name: key,
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      value,
      checked: formik.values[key] === value,
      disabled: isAttendanceConfirmNone,
    };
  };

  const getAttendanceConfirmProps = (
    value: LectureValue["attendanceConfirm"],
  ) => {
    return {
      name: "attendanceConfirm",
      onBlur: formik.handleBlur,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        formik.handleChange(e);
      },
      value,
      checked: formik.values.attendanceConfirm === value,
    };
  };

  const getAttendanceLimitDaysProps = (
    determineValueFn: (value: number) => OptionType,
  ) => {
    return {
      onChange: (option?: OnChangeValue<OptionType, false>) => {
        if (option) {
          formik.setFieldValue("attendanceLimitDays", option.value);
        }
      },
      value: determineValueFn(formik.values.attendanceLimitDays),
      isDisabled: isAttendanceConfirmNone,
    };
  };

  const nameError = formik.touched.name ? formik.errors.name : null;

  return {
    lectureNameProps,
    getAttendanceConfirmProps,
    getRadioGroupProps,
    getAttendanceLimitDaysProps,
    nameError,
  };
};

export const useQuestionnairesForm = () => {
  const formik = useFormikContext<LectureValue>();
  const isAttendanceConfirmNone = formik.values.attendanceConfirm === "none";

  const addQuestionnaireField = () => {
    const currentValues = formik.values.questionnaires;
    formik.setFieldValue("questionnaires", [
      ...currentValues,
      createDefaultQuestionnaire(),
    ]);
  };

  const getFormatProps = (
    questionnaireIndex: number,
    determineValueFn: (format: LectureQuestionnaireFormat) => OptionType,
  ) => {
    const format = formik.values.questionnaires[questionnaireIndex].format;
    const name = `questionnaires[${questionnaireIndex}].format`;

    return {
      value: determineValueFn(format),
      onChange: (selectedOption: OnChangeValue<OptionType, false>) => {
        if (selectedOption) {
          formik.setFieldValue(name, selectedOption.value);
        }
      },
      isDisabled: isAttendanceConfirmNone,
    };
  };

  const getDisabledProps = (questionnaireIndex: number) => {
    const disabled =
      isAttendanceConfirmNone ||
      formik.values.questionnaires[questionnaireIndex].disabled;
    return {
      value: !disabled, // disabled === false -> '表示中'
      onChange: (value: boolean) => {
        formik.setFieldValue(
          `questionnaires[${questionnaireIndex}].disabled`,
          !value,
        );
      },
    };
  };

  const getQuestionProps = (questionnaireIndex: number) => {
    return {
      value: formik.values.questionnaires[questionnaireIndex].question,
      name: `questionnaires[${questionnaireIndex}].question`,
      onChange: formik.handleChange,
      onBlur: (e: React.ChangeEvent<HTMLInputElement>) => {
        formik.values.questionnaires[questionnaireIndex].isInputting = true;
        formik.handleBlur(e);
      },
      hasError: Boolean(getQuestionError(questionnaireIndex)),
      disabled: isAttendanceConfirmNone,
    };
  };

  const getChoiceProps = ({
    questionnaireIndex,
    choiceIndex,
  }: {
    questionnaireIndex: number;
    choiceIndex: number;
  }) => {
    return {
      value:
        formik.values.questionnaires[questionnaireIndex].choices?.[choiceIndex],
      name: `questionnaires[${questionnaireIndex}].choices[${choiceIndex}]`,
      onChange: formik.handleChange,
      onBlur: (e: React.ChangeEvent<HTMLInputElement>) => {
        formik.values.questionnaires[questionnaireIndex].isInputting = true;
        formik.handleBlur(e);
      },
      hasError: Boolean(getChoiceError({ questionnaireIndex, choiceIndex })),
      disabled: isAttendanceConfirmNone,
    };
  };

  const getAddChoiceButtonProps = (questionnaireIndex: number) => {
    const onClick = () => {
      if (isAttendanceConfirmNone) return;

      const choices =
        formik.values.questionnaires[questionnaireIndex].choices ?? [];
      const newChoices = [...choices, ""];
      formik.setFieldValue(
        `questionnaires[${questionnaireIndex}].choices`,
        newChoices,
      );
    };

    return {
      onClick,
    };
  };

  const getRemoveChoiceButtonProps = ({
    questionnaireIndex,
    targetChoiceIndex,
  }: {
    questionnaireIndex: number;
    targetChoiceIndex: number;
  }) => {
    const onClick = () => {
      if (isAttendanceConfirmNone) return;

      const choices =
        formik.values.questionnaires[questionnaireIndex].choices ?? [];
      // 残り1件の時は消さない
      if (choices.length <= 1) {
        formik.setFieldTouched(
          `questionnaires[${questionnaireIndex}].choices`,
          true,
        );
        return;
      }
      if (
        choices[targetChoiceIndex] &&
        !window.confirm(
          `入力済みの選択肢："${choices[targetChoiceIndex]}" は削除されます。本当に削除しますか？`,
        )
      ) {
        return;
      }
      const newChoices = choices.filter((_, i) => i !== targetChoiceIndex);
      formik.setFieldValue(
        `questionnaires[${questionnaireIndex}].choices`,
        newChoices,
      );
    };
    return { onClick };
  };

  const addQuestionnaireButtonProps = {
    onClick: () => {
      if (isAttendanceConfirmNone) return;

      if (
        formik.values.questionnaires.filter((qs) => !qs.deleted).length >=
        MAX_QUESTION_COUNT
      ) {
        alert(`登録できる質問は最大${MAX_QUESTION_COUNT}件までです`);
        return;
      }
      addQuestionnaireField();
    },
  };

  const getRemoveQuestionnaireButtonProps = (questionnaierIndex: number) => {
    const onClick = () => {
      if (isAttendanceConfirmNone) return;

      const questionnaire = formik.values.questionnaires[questionnaierIndex];
      if (isQuestionnaireFilling(questionnaire)) {
        const displayQuestion =
          questionnaire.question.length > 0 ? questionnaire.question : "";
        if (
          !window.confirm(
            `アンケート項目："${displayQuestion}" はいくつかの項目が入力済みです。本当に削除しますか？`,
          )
        ) {
          return;
        }
      }

      const questionnaires = questionnaire.id
        ? // 登録済みのアンケート項目の場合はdeletedフラグを立ててサーバーに送る
          formik.values.questionnaires.map((qs) => ({
            ...qs,
            deleted: qs.id && qs.id === questionnaire.id ? true : qs.deleted,
          }))
        : // 新規登録のアンケート項目に対して削除ボタンが押されたら、シンプルにquestionnairesの配列から消す
          formik.values.questionnaires.filter(
            (_, i) => i !== questionnaierIndex,
          );

      formik.setValues({
        ...formik.values,
        questionnaires,
      });
    };
    return { onClick };
  };

  const getQuestionError = (questionnaireIndex: number) => {
    const questionnaire = formik.errors.questionnaires?.[questionnaireIndex];
    const touched = Boolean(
      formik.touched.questionnaires?.[questionnaireIndex],
    );

    if (
      !questionnaire ||
      typeof questionnaire === "string" || // 型が string | FormikErrors<LectureQuestionnaire> | undefined で解決されるため
      !questionnaire.question ||
      !touched
    ) {
      return null;
    }
    return questionnaire.question;
  };

  const getChoiceError = ({
    questionnaireIndex,
    choiceIndex,
  }: {
    questionnaireIndex: number;
    choiceIndex: number;
  }) => {
    const questionnaire = formik.errors.questionnaires?.[questionnaireIndex];
    const touched = formik.touched.questionnaires?.[questionnaireIndex];

    if (
      !questionnaire ||
      typeof questionnaire === "string" ||
      !questionnaire.choices ||
      !touched ||
      !touched.choices
    ) {
      return null;
    }

    if (Array.isArray(questionnaire.choices)) {
      // 選択肢ごとのバリデーションエラー結果を返す
      return questionnaire.choices[choiceIndex];
    } else if (choiceIndex === 0) {
      // 入力項目を複数表示しているときに、どれにも入力がない場合、代表として一番最初のInputの下に出す
      return questionnaire.choices;
    } else {
      return null;
    }
  };

  return {
    isEditing: Boolean(formik.values.id),
    getQuestionProps,
    getFormatProps,
    getChoiceProps,
    getDisabledProps,
    questionnaires: formik.values.questionnaires,
    getAddChoiceButtonProps,
    getRemoveChoiceButtonProps,
    addQuestionnaireButtonProps,
    getRemoveQuestionnaireButtonProps,
    getQuestionError,
    getChoiceError,
    isAttendanceConfirmNone,
  };
};

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("講座名を入力してください")
    .max(64, "講座名は64文字以内で入力してください"),
  attendanceConfirm: yup.string().required(),
  attendanceLimitDays: yup.number(),
  attendanceLocationCollect: yup.string().required(),
  questionnaires: yup.array(
    yup.object().shape({
      isInputting: yup.boolean(),
      deleted: yup.boolean(),
      format: yup.string().required(),
      question: yup.string().when(["deleted", "isInputting"], {
        is: (deleted: boolean, isInputting: boolean) => deleted || !isInputting,
        then: (schema) => schema,
        otherwise: (schema) =>
          schema
            .required("質問文の入力は必須です")
            .max(500, "質問文は500文字以内で入力してください"),
      }),
      choices: yup
        .array(yup.string())
        .when(["format", "deleted", "isInputting"], {
          is: (
            format: LectureQuestionnaireFormat,
            deleted: boolean,
            isInputting: boolean,
          ) => {
            return (
              format !== LectureQuestionnaireFormat.free &&
              !deleted &&
              isInputting
            );
          },
          then: (schema) =>
            schema
              .test(
                "at-least-1-valid-option",
                "少なくとも1つの回答選択肢を設定してください",
                (array?: (string | undefined)[]) => {
                  // DEFNITION:
                  // 選択肢入力テキストボックスが複数表示中1つでも入力されていればvalid、入力がある項目だけをpostする
                  // 選択肢入力テキストボックスが複数表示中1つも入力されている項目が*なければ*invalid

                  // NOTE:
                  // formatが選択肢の時、ユーザービリティのためデフォルトで空の文字列を配列に入れて入力項目を1行確保するようにしている。
                  // この時、yupはvalidation時に[""]ではなく[undefined]として評価しているため、undefinedの配列であることを考慮に入れている

                  return (
                    array?.some(
                      (el) => typeof el !== "undefined" && el.length > 0,
                    ) ?? false
                  );
                },
              )
              .of(
                yup.string().max(500, "選択肢は500文字以内で入力してください"),
              ),
          otherwise: (schema) => schema,
        }),
    }),
  ),
});

export const createDefaultQuestionnaire = (): QuestionnaireValue => {
  return {
    format: "single",
    choices: ["", ""],
    question: "",
    disabled: false,
    deleted: false,
    isInputting: false, // 一度でも入力があればtrueとなりバリデーション対象やサブミット対象となる(初期状態の質問事項のまま登録できるようにさせるため)
  };
};

export const defaultValues: LectureValue = {
  name: "",
  attendanceConfirm: "none",
  attendanceLocationCollect: "off",
  attendanceLimitDays: Infinity, // react-select で null/undefined は valueとして使用できないため
  questionnaires: new Array(createDefaultQuestionnaire()),
};

const isQuestionnaireFilling = (questionnaire: QuestionnaireValue) => {
  if (
    (questionnaire.question && questionnaire.question.length > 0) ||
    (questionnaire.choices && questionnaire.choices.some((c) => c.length > 0))
  ) {
    return true;
  }
  return false;
};
