import { useCallback, useState } from "react";

export const useAnnounceDestinationModal = () => {
  const [isDestinationTagModalOpen, setIsDestinationTagModalOpen] =
    useState(false);

  const openDestinationTagModal = useCallback(() => {
    setIsDestinationTagModalOpen(true);
  }, []);

  const closeDestinationTagModal = useCallback(() => {
    setIsDestinationTagModalOpen(false);
  }, []);

  return {
    isDestinationTagModalOpen,
    openDestinationTagModal,
    closeDestinationTagModal,
  };
};
