import { FC, ReactNode, useState } from "react";

import CircleIcon from "../../../components/atoms/CircleIcon";
import Loader from "../../../components/atoms/Loader";
import AnnounceMessageRecipientsModal from "../../../components/features/AnnounceMessageRecipientsModal";
import { RegexpHelper } from "../../../helpers/RegexpHelper";
import TimeHelper from "../../../helpers/TimeHelper";
import { useFlashMessage } from "../../../hooks/useFlashMessage";
import { AnnounceMessage } from "../../../interfaces/MessageInterface";
import { AnnounceDestinationTagWithFormStateInterface } from "../../../interfaces/SectionAnnounceMessagesStateInterface";
import SectionInterface from "../../../interfaces/SectionInterface";
import { HTTP_ERROR_MESSAGE } from "../../../reducers";
import { useFetchAnnounceMessageRecipients } from "../useFetchAnnounceMessageRecipients";
import { useFetchAnnounceMessages } from "../useFetchAnnounceMessages";
import styles from "./styles.scss";
import { useAnnounceMessageScroll } from "./useAnnounceMessageScroll";
const reactStringReplace = require("react-string-replace");
const nl2br = require("react-nl2br");

interface Props {
  destinationTag: AnnounceDestinationTagWithFormStateInterface;
  section: SectionInterface;
}

const AnnounceMessageList: FC<Props> = (props: Props) => {
  const [selectedMessageId, setSelectedMessageId] = useState<string | null>(
    null,
  );
  const [isRecipientsModalOpen, setIsRecipientsModalOpen] = useState(false);

  const { messages, isPending, isFetchingNextPage, loadMore } =
    useFetchAnnounceMessages({
      sectionId: props.section.id,
      tag: props.destinationTag,
    });

  const { scroller, handleScroll } = useAnnounceMessageScroll({
    messages,
    loadMore,
  });

  const openRecipientsModal = (announceMessage: AnnounceMessage) => {
    setSelectedMessageId(announceMessage.id);
    setIsRecipientsModalOpen(true);
  };

  const closeRecipientsModal = () => {
    setIsRecipientsModalOpen(false);
  };

  const receivedTotalCount = messages?.find(
    (message) => message.id === selectedMessageId,
  )?.receivedTotalCount;

  if (isPending) {
    return (
      <div className={styles.listContainer}>
        <Loader loading />
      </div>
    );
  }

  return (
    <div
      className={styles.listContainer}
      ref={scroller}
      onScroll={handleScroll}
    >
      <div className={styles.list}>
        {messages?.map((message) => (
          <AnnounceMessageListItem
            message={message}
            section={props.section}
            key={`AnnounceMessageList-GuardianMessageListItem-${message.id}`}
            onOpenRecipientsModal={openRecipientsModal}
          />
        ))}
        {isFetchingNextPage && <Loader loading />}
      </div>

      {selectedMessageId && props.destinationTag && (
        <RecipientsModal
          sectionId={props.section.id}
          announceMessageId={selectedMessageId}
          isRecipientsModalOpen={isRecipientsModalOpen}
          closeRecipientsModal={closeRecipientsModal}
          destinationTag={props.destinationTag}
          receivedTotalCount={receivedTotalCount ?? 0}
        />
      )}
    </div>
  );
};

const AnnounceMessageListItem = (props: {
  message: AnnounceMessage;
  section: SectionInterface;
  onOpenRecipientsModal: (message: AnnounceMessage) => void;
}) => {
  const { message, section, onOpenRecipientsModal } = props;
  const { sentAt, receivedTotalCount } = message;
  const openRecipientsModal = () => {
    onOpenRecipientsModal(message);
  };

  return (
    <div className={styles.message} data-testid="announce-message-listitem">
      <div className={styles.message__header}>
        <CircleIcon
          iconProps={{ name: "icon-account", iconClassName: styles.icon }}
          className={styles.iconWrapper}
        />
        <span className={styles.name}>{section.fullName}</span>
        <time className={styles.datetime}>{TimeHelper.fullFormat(sentAt)}</time>
        <div className={styles.recipientsDropdown}>
          <span className={styles.dropdownLabel} onClick={openRecipientsModal}>
            {`受信者一覧 ${
              receivedTotalCount > 0 ? `（既読 ${receivedTotalCount}名）` : ""
            }`}
          </span>
        </div>
      </div>
      {renderMessageBody(message)}
    </div>
  );
};

const renderMessageBody = (message: AnnounceMessage) => {
  if (message.presignedUrl) {
    return (
      <a href={message.presignedUrl} target="_blank" rel="noopener noreferrer">
        <img src={message.presignedUrl} className={styles.image} />
      </a>
    );
  } else {
    return (
      <p className={styles.message__content}>
        {reactStringReplace(
          nl2br(message.content),
          RegexpHelper.URL,
          (match: string): ReactNode => (
            <a href={match} target="_blank" rel="noopener noreferrer">
              {match}
            </a>
          ),
        )}
      </p>
    );
  }
};

const RecipientsModal = ({
  sectionId,
  announceMessageId,
  isRecipientsModalOpen,
  closeRecipientsModal,
  destinationTag,
  receivedTotalCount,
}: {
  sectionId: string;
  announceMessageId: string;
  isRecipientsModalOpen: boolean;
  closeRecipientsModal: () => void;
  destinationTag: AnnounceDestinationTagWithFormStateInterface;
  receivedTotalCount: number;
}) => {
  const { showErrorMessage } = useFlashMessage();
  const { recipients, isPending, hasNextPage, loadMore, error } =
    useFetchAnnounceMessageRecipients({
      sectionId,
      announceMessageId,
    });
  if (error) {
    showErrorMessage(HTTP_ERROR_MESSAGE);
    closeRecipientsModal();
  }

  return (
    <AnnounceMessageRecipientsModal
      isOpen={isRecipientsModalOpen}
      onClose={closeRecipientsModal}
      destinationTag={destinationTag}
      totalCount={destinationTag.studentCount ?? 0}
      recipients={recipients ?? []}
      isLoading={isPending}
      hasNextPage={hasNextPage}
      loadMore={loadMore}
      receivedTotalCount={receivedTotalCount}
    />
  );
};

export default AnnounceMessageList;
