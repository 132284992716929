import { useInfiniteQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { boronClient } from "../../api.ts";
import StudentTag from "../../domains/StudentTag.ts";
import TagEntity from "../../entities/TagEntity.ts";
import { HTTPErrors, createError } from "../../errors.ts";
import { getNextPageParam } from "../../helpers/ReactQueryHelper.ts";
import ApiResponse from "../../interfaces/ApiResponse.ts";
import StudentInterface from "../../interfaces/StudentInterface.ts";

export const useFetchDestinationTagRecipients = ({
  sectionId,
  destinationTag,
}: {
  sectionId: string;
  destinationTag: StudentTag | null;
}) => {
  const query = buildQuery(destinationTag);

  const result = useInfiniteQuery<ApiResponse<StudentInterface[]>, HTTPErrors>({
    queryKey: ["/api/v1/sections/{section_id}/students", sectionId, query],
    queryFn: async ({ pageParam }) => {
      const { response, data } = await boronClient.GET(
        "/api/v1/sections/{section_id}/students",
        {
          params: {
            path: {
              section_id: sectionId,
            },
            query: {
              ...query,
              page: pageParam as number,
            },
          },
        },
      );

      if (response.ok && data) {
        return data.students;
      }

      throw await createError(response);
    },
    getNextPageParam,
    initialPageParam: 1,
    enabled: !!destinationTag,
  });

  const recipients = useMemo(
    () => result.data?.pages.flatMap((page) => page.data) || null,
    [result.data],
  );

  return {
    recipients,
    ...result,
  };
};

const buildQuery = (destinationTag: StudentTag | null) => {
  if (!destinationTag) {
    return {};
  }

  const queryParams = new TagEntity(destinationTag).toQueryParams();
  return {
    ...queryParams,
    status: ["active"],
  };
};
