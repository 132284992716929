import {
  IconAttention2,
  IconLock,
  IconMemo,
  IconTimeline,
} from "@studyplus/boron-ui";
import Time from "../../components/atoms/Time";
import { TimelineCommentButton } from "../../components/features/Timeline/TimelineItem/TimelineItemFooter/TimelineCommentButton";
import { TimelineLikeButton } from "../../components/features/Timeline/TimelineItem/TimelineItemFooter/TimelineLikeButton";
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverProvider,
  PopoverTrigger,
} from "../../components/general/Popover/Popover";
import OperatorInterface from "../../interfaces/OperatorInterface";
import StudentInterface from "../../interfaces/StudentInterface";
import type {
  StudyRecord,
  StudyRecordWithRestrictedAttributes,
} from "../../interfaces/StudyRecordInterface";
import { useFetchSectionsTimeline } from "../SectionsTimelinePage/useFetchSectionsTimeline";
import { useFetchSectionsTimelineSummary } from "./useFetchSectionsTimelineSummary";

type Props = {
  currentOperator: OperatorInterface;
  studyRecord: StudyRecordWithRestrictedAttributes;
  student: StudentInterface;
  sectionId: string;
  canSendComment: boolean;
  updateStudyRecordQueryCache:
    | ReturnType<
        typeof useFetchSectionsTimelineSummary
      >["updateStudyRecordQueryCache"]
    | ReturnType<
        typeof useFetchSectionsTimeline
      >["updateStudyRecordQueryCache"];
};

export const StudyRecordItem = (props: Props) => {
  return (
    <div className="relative m-2 h-auto min-w-[14rem] max-w-[14rem] rounded-sm border border-solid border-gray-400">
      <CommentIcon hasComment={!!props.studyRecord.comment} />
      <Body {...props} />
      <Footer {...props} />
    </div>
  );
};

const CommentIcon = ({ hasComment }: { hasComment: boolean }) => {
  // TODO: このアイコンは仮なので変更予定
  if (hasComment) {
    return (
      <IconAttention2 className="absolute right-0 top-0 p-1 text-blue-400 group-hover:opacity-50" />
    );
  } else {
    return (
      <IconAttention2 className="absolute right-0 top-0 p-1 text-gray-400 group-hover:opacity-50" />
    );
  }
};

const Body = (props: Props) => {
  const { studyRecord } = props;
  return (
    <div className="group-hover:opacity-50">
      <LearningMaterialImage learningMaterial={studyRecord.learningMaterial} />
      <StudyRecordContent studyRecord={studyRecord} />
      <LearningMaterialName learningMaterial={studyRecord.learningMaterial} />
    </div>
  );
};

const LearningMaterialImage = ({
  learningMaterial,
}: {
  learningMaterial: StudyRecordWithRestrictedAttributes["learningMaterial"];
}) => {
  return (
    <div className="flex min-h-[12rem] min-w-[10rem] items-center justify-center pt-7 text-center">
      {learningMaterial && learningMaterial.imageUrl && (
        <img className="h-auto w-[8rem]" src={learningMaterial.imageUrl} />
      )}
    </div>
  );
};

const StudyRecordContent = ({ studyRecord }: Pick<Props, "studyRecord">) => {
  const isRestrictions =
    studyRecord.restrictedAttributes.includes("numberOfSeconds") &&
    studyRecord.restrictedAttributes.includes("amount");

  if (isRestrictions) {
    return <RestrictedStudyRecordTime />;
  } else {
    return <StudyRecord studyRecord={studyRecord} />;
  }
};

const RestrictedStudyRecordTime = () => {
  return (
    <PopoverProvider>
      <Popover>
        <PopoverTrigger className="flex cursor-pointer">
          <div className="mt-4 flex justify-center">
            <IconLock className="text-gray-800" />
            <div>
              <Time seconds={0} size="small" isRestrictions />
            </div>
          </div>
        </PopoverTrigger>
        <PopoverPortal>
          <PopoverContent>
            <div className="text-center">
              プロプランにすると生徒が記録した
              <br />
              全学習記録が閲覧できます
            </div>
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    </PopoverProvider>
  );
};

const StudyRecord = ({ studyRecord }: Pick<Props, "studyRecord">) => {
  return (
    <div className="mt-4 flex w-full justify-center">
      {studyRecord.numberOfSeconds && studyRecord.numberOfSeconds > 0 ? (
        <>
          <IconTimeline className="text-gray-800" />
          <div>
            <Time seconds={studyRecord.numberOfSeconds} size="small" />
          </div>
        </>
      ) : studyRecord.amount && studyRecord.amount > 0 ? (
        <>
          <IconMemo className="min-w-fit text-gray-800" />
          <Amount unit={studyRecord.unit} amount={studyRecord.amount} />
        </>
      ) : (
        // NOTE: 要素の高さを合わせるためにinvisibleでスペースを確保
        <>
          <div className="invisible">
            <Amount unit={""} amount={0} />
          </div>
        </>
      )}
    </div>
  );
};

type AmountProps = {
  amount: number;
  unit: string;
};
const Amount = (props: AmountProps) => {
  return (
    <div className="overflow-hidden text-ellipsis whitespace-nowrap">
      <span className="mx-1 text-3xl">{props.amount}</span>
      <span>{props.unit}</span>
    </div>
  );
};

const LearningMaterialName = ({
  learningMaterial,
}: {
  learningMaterial: StudyRecordWithRestrictedAttributes["learningMaterial"];
}) => {
  return (
    <div className="mb-12 line-clamp-2 h-7 px-6 pt-2">
      {learningMaterial && learningMaterial.name
        ? learningMaterial.name
        : "教材なし"}
    </div>
  );
};

const Footer = (props: Props) => {
  const {
    currentOperator,
    studyRecord,
    student,
    canSendComment,
    updateStudyRecordQueryCache,
  } = props;
  return (
    <div className="absolute bottom-0 mb-2 mt-4 flex w-full justify-evenly hover:opacity-100">
      <TimelineLikeButton
        currentOperator={currentOperator}
        studyRecord={studyRecord}
        student={student}
        canSendComment={canSendComment}
        noLabel={true}
        updateStudyRecordQueryCache={updateStudyRecordQueryCache}
      />

      <TimelineCommentButton
        restrictedAttributes={studyRecord.restrictedAttributes}
        totalComments={studyRecord.comments.length}
        toggleVisibility={() => {}}
        noLabel={true}
      />
    </div>
  );
};
