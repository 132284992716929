import { FC, Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Icon from "../../../components/atoms/Icon";
import SplitLayout, { Column } from "../../../components/atoms/SplitLayout";
import TabsNavi from "../../../components/molecules/TabsNavi";
import StudentTag from "../../../domains/StudentTag";
import AnnounceDestinationTagInterface from "../../../interfaces/AnnounceDestinationTagInterface";
import { OperatorPrivilegeInfoInterface } from "../../../interfaces/OperatorInterface";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import SectionInterface from "../../../interfaces/SectionInterface";
import AnnounceMessage from "../AnnounceMessage";
import SectionAnnounceDestinationTagList from "../SectionAnnounceDestinationTagList";
import { Params } from "../index";
import { useAnnounceDestinationTag } from "../useAnnounceDestinationTag";
import styles from "./styles.scss";
import { useAnnounceDestinationModal } from "./useAnnounceDestinationModal";

interface Props {
  params: Params;
  section: SectionInterface;
  operatorPrivilege: OperatorPrivilegeInfoInterface | null;
  destinationTags: AnnounceDestinationTagInterface[];
  allTags: StudentTag[];
  isLoading: boolean;
  isFetchingNextPage: boolean;
  currentOperator: OperatorInterface;
  onLoadMore: () => void;
}

const SectionAnnounceMessages: FC<Props> = ({
  params,
  section,
  operatorPrivilege,
  destinationTags,
  allTags,
  isLoading,
  isFetchingNextPage,
  currentOperator,
  onLoadMore,
}) => {
  const location = useLocation();
  const [focusedColumn, setFocusedColumn] = useState<Column>(
    "action" in params && params.action === "new" ? Column.Right : Column.Left,
  );

  const {
    openedTag,
    openAnnounceDestinationTagById,
    closeAnnounceDestinationTag,
  } = useAnnounceDestinationTag({
    tags: allTags,
  });

  useEffect(() => {
    // URL変更時またはコンポーネントマウント時のタグ選択処理
    if ("action" in params && params.action === "new") {
      closeAnnounceDestinationTag();
    } else if ("destinationTagId" in params) {
      openAnnounceDestinationTagById(params.destinationTagId);
    } else {
      // 宛先タグの指定がなく、新規登録でもない場合は最初のタグを選択
      const firstDestinationTag = destinationTags?.[0];
      if (firstDestinationTag) {
        openAnnounceDestinationTagById(firstDestinationTag.id);
      }
    }
  }, [params]);

  const {
    isDestinationTagModalOpen,
    openDestinationTagModal,
    closeDestinationTagModal,
  } = useAnnounceDestinationModal();

  const handleClickNewMessage = () => {
    openDestinationTagModal();
    handleNavigateColumn();
  };

  const handleNavigateColumn = () => {
    setFocusedColumn(Column.Right);
  };

  const handleCloseRightColumn = () => {
    setFocusedColumn(Column.Left);
  };

  const tabs = [
    {
      label: "個別送信",
      link: {
        pathname: `/sections/${section.id}/message_threads`,
        search: location.search,
      },
      active: false,
    },
  ];

  if (
    operatorPrivilege?.role === "admin" ||
    operatorPrivilege?.role === "unlimited_member"
  ) {
    tabs.push({
      label: "一斉送信",
      link: {
        pathname: `/sections/${section.id}/tags/messages`,
        search: location.search,
      },
      active: true,
    });
  }

  return (
    <Fragment>
      <TabsNavi tabs={tabs} />
      <div className={styles.message}>
        <SplitLayout focusedColumn={focusedColumn}>
          <SplitLayout.Navigation focusedColumn={focusedColumn}>
            <div className={styles.newMessage}>
              <Link
                to={`/sections/${section.id}/tags/messages/new`}
                className={styles.newMessage__button}
                onClick={handleClickNewMessage}
              >
                <Icon name="icon-edit" />
                新しいメッセージを作成
              </Link>
            </div>
            <SectionAnnounceDestinationTagList
              sectionId={section.id}
              destinationTags={destinationTags}
              openedTagId={openedTag?.id ?? null}
              isLoading={isLoading}
              isFetchingNextPage={isFetchingNextPage}
              onLoadMore={onLoadMore}
              onClickTag={handleNavigateColumn}
            />
          </SplitLayout.Navigation>
          <AnnounceMessage
            destinationTag={openedTag}
            onCloseRightColumn={handleCloseRightColumn}
            section={section}
            isDestinationTagModalOpen={isDestinationTagModalOpen}
            onOpenDestinationTagModal={openDestinationTagModal}
            onCloseDestinationTagModal={closeDestinationTagModal}
            currentOperator={currentOperator}
          />
        </SplitLayout>
      </div>
    </Fragment>
  );
};

export default SectionAnnounceMessages;
