import { Button } from "@studyplus/boron-ui";
import StudentTag from "../../../domains/StudentTag";
import { Recipient } from "../../../interfaces/SectionAnnounceMessagesStateInterface";
import Loader from "../../atoms/Loader";
import { Modal } from "../../atoms/Modal";
import styles from "./styles.scss";

interface Props {
  destinationTag: StudentTag;
  isOpen: boolean;
  onClose: () => void;
  totalCount: number;
  recipients: Recipient[];
  isLoading: boolean;
  hasNextPage: boolean;
  loadMore: () => void;

  // NOTE: 現状は生徒の一斉メッセージの既読数のみを表示するためoptionalにしている
  receivedTotalCount?: number | undefined;
}

const AnnounceMessageRecipientsModal = ({
  destinationTag,
  isOpen,
  onClose,
  totalCount,
  recipients,
  isLoading,
  hasNextPage,
  loadMore,
  receivedTotalCount,
}: Props) => {
  const isReceivedTotalCountVisible =
    receivedTotalCount !== undefined && receivedTotalCount > 0;

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className={styles.root}>
      <Modal.Header onClose={onClose}>
        {destinationTag && (
          <h2 className="flex gap-8">
            <div>受信者：{totalCount}名</div>
            {isReceivedTotalCountVisible && (
              <div>既読：{receivedTotalCount}名</div>
            )}
          </h2>
        )}
      </Modal.Header>
      <div className={styles.recipients}>
        <ul>
          {recipients.map((recipient: Recipient) => {
            return (
              <li key={recipient.id} className={styles.recipient}>
                <div className="flex justify-between">
                  <p>{recipient.fullName}</p>
                  {recipient.receivedAt && <p className="pr-5 text-md">既読</p>}
                </div>
              </li>
            );
          })}
        </ul>
        <Loader loading={isLoading} />
        {hasNextPage && !isLoading && (
          <Button
            onClick={loadMore}
            disabled={false}
            variant="outline"
            isBlock={true}
            size="xs"
            className={styles.recipients__btn}
          >
            もっと見る
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default AnnounceMessageRecipientsModal;
