import Loader from "../../../components/atoms/Loader";
import AnnounceDestinationTagInterface from "../../../interfaces/AnnounceDestinationTagInterface";
import AnnounceDestinationTagListItem from "../AnnounceDestinationTagListItem";
import styles from "./styles.scss";

interface Props {
  sectionId: string;
  destinationTags: AnnounceDestinationTagInterface[] | null;
  openedTagId: string | null;
  isLoading: boolean;
  isFetchingNextPage: boolean;
  onLoadMore: () => void;
  onClickTag: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

const SectionAnnounceDestinationTagList: React.FC<Props> = ({
  sectionId,
  destinationTags,
  openedTagId,
  isLoading,
  isFetchingNextPage,
  onLoadMore,
  onClickTag,
}) => {
  if (isLoading) {
    return <Loader loading />;
  }

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (isLoading || isFetchingNextPage) {
      return;
    }

    const scrollBottom =
      e.currentTarget.scrollTop + e.currentTarget.offsetHeight;
    if (scrollBottom + 100 >= e.currentTarget.scrollHeight) {
      onLoadMore();
    }
  };

  return (
    <div className={styles.root} onScroll={handleScroll}>
      <TagList
        sectionId={sectionId}
        destinationTags={destinationTags}
        openedTagId={openedTagId}
        onClickTag={onClickTag}
      />
      {isFetchingNextPage && <Loader loading />}
    </div>
  );
};

interface TagListProps {
  sectionId: string;
  destinationTags: AnnounceDestinationTagInterface[] | null;
  openedTagId: string | null;
  onClickTag: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

const TagList: React.FC<TagListProps> = ({
  sectionId,
  destinationTags,
  openedTagId,
  onClickTag,
}) => {
  if (!destinationTags) {
    return null;
  }

  return (
    <>
      {destinationTags.map((destinationTag) => (
        <AnnounceDestinationTagListItem
          key={destinationTag.id}
          destinationTag={destinationTag}
          isActive={!!openedTagId && openedTagId === destinationTag.id}
          sectionId={sectionId}
          onClickTag={onClickTag}
        />
      ))}
    </>
  );
};

export default SectionAnnounceDestinationTagList;
