import isAfter from "date-fns/isAfter";
import set from "date-fns/set";
import { useFormik } from "formik";
import * as yup from "yup";
import { parseDateTime } from "../../helpers/TimeHelper";
import { StayFormInterface } from "../../interfaces/StayInterface";

type Values = {
  date: Date;
  enteredAt: string;
  exitedAt: string;
};

type Props = {
  initialValues: Values;
  onSubmit: (value: StayFormInterface) => void;
};
export const useFormikWithStudentStay = ({
  initialValues,
  onSubmit,
}: Props) => {
  return useFormik<Values>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit(values) {
      onSubmit(updateParams(values));
    },
  });
};

const updateParams = (values: Values) => {
  const enteredAt = values.enteredAt.split(":");
  const exitedAt = values.exitedAt.split(":");
  return {
    entered_at: set(values.date, {
      hours: parseInt(enteredAt[0]),
      minutes: parseInt(enteredAt[1]),
    }).toISOString(),
    exited_at: values.exitedAt
      ? set(values.date, {
          hours: parseInt(exitedAt[0]),
          minutes: parseInt(exitedAt[1]),
        }).toISOString()
      : "",
  };
};

const validationSchema = yup
  .object<Record<keyof Values, yup.AnyObjectSchema>>()
  .shape({
    enteredAt: yup.string().trim().required("入室時間を入力してください"),
    exitedAt: yup.string().when(["enteredAt"], {
      is: (enteredAt: string) => !!enteredAt,
      then: (schema) =>
        schema.test({
          message: `退室時間は入室時間より後に設定してください`,
          test: (value, context) => {
            const values: Values = context.parent;
            return validateTime({
              date: values.date,
              enteredAt: values.enteredAt,
              exitedAt: value ?? "",
            });
          },
        }),
    }),
  });

const validateTime = ({ date, enteredAt, exitedAt }: Values) => {
  if (exitedAt == "") return true;
  const startAt = parseDateTime(date, enteredAt);
  const endAt = parseDateTime(date, exitedAt);
  return !isAfter(startAt, endAt);
};
