import classNames from "classnames";
import { FC } from "react";
import Icon from "../../../components/atoms/Icon";
import { AnnounceDestinationTagWithFormStateInterface } from "../../../interfaces/SectionAnnounceMessagesStateInterface";
import { Recipients } from "./Recipients";
import styles from "./styles.scss";

interface DestinationOptionProps {
  sectionId: string;
  destinationTag: AnnounceDestinationTagWithFormStateInterface;
  isOpened: boolean;
  isSelected: boolean;
  isDisabled: boolean;
  onSelect: (tag: AnnounceDestinationTagWithFormStateInterface) => () => void;
  onOpen: (tag: AnnounceDestinationTagWithFormStateInterface) => () => void;
  onClose: () => void;
}

export const DestinationOption: FC<DestinationOptionProps> = ({
  sectionId,
  destinationTag,
  isOpened,
  isSelected,
  isDisabled,
  onSelect,
  onOpen,
  onClose,
}) => {
  if (destinationTag.type === "status" && destinationTag.id !== "active") {
    return null;
  }

  return (
    <>
      <label
        className={classNames(styles.label, {
          [styles.active]: isSelected,
          [styles.labelDisabled]: isDisabled,
        })}
        data-testid="announce-message-destination-modal-option-listitem"
      >
        <div
          className={classNames(styles.row, {
            [styles.disabledRow]: isDisabled,
          })}
        >
          <input
            type="radio"
            name={destinationTag.id}
            checked={isSelected}
            onChange={onSelect(destinationTag)}
            disabled={isDisabled}
          />
          <div className={styles.tagName}>
            {destinationTag.name}（{destinationTag.studentCount}名）
          </div>
        </div>
        {isOpened ? (
          <Icon name="icon-arrow-up" onClick={onClose} />
        ) : (
          <Icon
            name="icon-arrow-down"
            className={styles.icon}
            onClick={onOpen(destinationTag)}
          />
        )}
      </label>
      {isOpened ? (
        <Recipients tag={destinationTag} sectionId={sectionId} />
      ) : null}
    </>
  );
};
