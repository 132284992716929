import * as React from "react";
import { useFetchSectionsTimeline } from "../../../../../features/SectionsTimelinePage/useFetchSectionsTimeline";
import { useFetchSectionsTimelineSummary } from "../../../../../features/SectionsTimelineSummaryPage/useFetchSectionsTimelineSummary";
import OperatorInterface from "../../../../../interfaces/OperatorInterface";
import StudentInterface from "../../../../../interfaces/StudentInterface";
import type { StudyRecordWithRestrictedAttributes } from "../../../../../interfaces/StudyRecordInterface";
import { TimelineCommentButton } from "./TimelineCommentButton";
import { TimelineComments } from "./TimelineComments";
import { TimelineInsertTemplateCommentButton } from "./TimelineInsertTemplateCommentButton";
import { TimelineLikeButton } from "./TimelineLikeButton";
import styles from "./styles.scss";
import { useTimelineComments } from "./useTimelineComments";

interface Props {
  currentOperator: OperatorInterface;
  studyRecord: StudyRecordWithRestrictedAttributes;
  student: StudentInterface;
  canSendComment: boolean;
  updateStudyRecordQueryCache:
    | ReturnType<
        typeof useFetchSectionsTimelineSummary
      >["updateStudyRecordQueryCache"]
    | ReturnType<
        typeof useFetchSectionsTimeline
      >["updateStudyRecordQueryCache"];
  isLastItem: boolean;
}

const TimelineItemFooter = ({
  currentOperator,
  studyRecord,
  student,
  canSendComment,
  updateStudyRecordQueryCache,
  isLastItem,
}: Props) => {
  const {
    comments,
    isVisible,
    onAddComment,
    onDeleteComment,
    toggleVisibility,
    setIsVisible,
  } = useTimelineComments({ studyRecord, updateStudyRecordQueryCache });
  const [comment, setComment] = React.useState("");

  return (
    <div className={styles.root}>
      <div className={styles.buttons}>
        <TimelineCommentButton
          restrictedAttributes={studyRecord.restrictedAttributes}
          totalComments={comments.length}
          toggleVisibility={toggleVisibility}
        />
        <TimelineLikeButton
          currentOperator={currentOperator}
          studyRecord={studyRecord}
          student={student}
          canSendComment={canSendComment}
          updateStudyRecordQueryCache={updateStudyRecordQueryCache}
        />
        {canSendComment && (
          <TimelineInsertTemplateCommentButton
            studyRecord={studyRecord}
            student={student}
            comment={comment}
            setComment={setComment}
            setIsVisible={setIsVisible}
          />
        )}
      </div>
      <TimelineComments
        studyRecord={studyRecord}
        student={student}
        canSendComment={canSendComment}
        isVisible={isVisible}
        comments={comments}
        onAddComment={onAddComment}
        onDeleteComment={onDeleteComment}
        comment={comment}
        setComment={setComment}
        requireScrollIntoView={isLastItem}
      />
    </div>
  );
};

export default TimelineItemFooter;
