import {
  InfiniteData,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { boronClient } from "../../api";
import StudentTag from "../../domains/StudentTag";
import { HTTPErrors, createError } from "../../errors";
import { getNextPageParam } from "../../helpers/ReactQueryHelper";
import ApiResponse from "../../interfaces/ApiResponse";
import { AnnounceMessage } from "../../interfaces/MessageInterface";

type AnnounceMessagesResponse = ApiResponse<AnnounceMessage[]>;

const MESSAGES_PER_PAGE = 10;

const buildKey = (sectionId: string, tag: StudentTag) => [
  "/api/v1/sections/{section_id}/announce_messages",
  sectionId,
  tag.id,
  tag.type,
];

export const useFetchAnnounceMessages = ({
  sectionId,
  tag,
}: {
  sectionId: string;
  tag: StudentTag;
}) => {
  const result = useInfiniteQuery<AnnounceMessagesResponse, HTTPErrors>({
    queryKey: buildKey(sectionId, tag),
    queryFn: async ({ pageParam }) => {
      if (!tag) {
        throw new Error("Tag is required");
      }
      const { data, response } = await boronClient.GET(
        "/api/v1/sections/{section_id}/announce_messages",
        {
          params: {
            path: {
              section_id: sectionId,
            },
            query: {
              destination_id: tag.id,
              type: tag.type,
              per: MESSAGES_PER_PAGE,
              page: pageParam as number,
            },
          },
        },
      );

      if (response.ok && data) {
        return data.messages;
      }

      throw await createError(response);
    },
    initialPageParam: 1,
    getNextPageParam,
  });

  const messages = useMemo(
    () => result.data?.pages.flatMap((page) => page.data) || null,
    [result.data],
  );

  const loadMore = () => {
    if (!result.isFetchingNextPage && result.hasNextPage) {
      result.fetchNextPage();
    }
  };

  return {
    ...result,
    messages,
    loadMore,
  };
};

export const useAddAnnounceMessageToCache = ({
  sectionId,
  tag,
}: {
  sectionId: string;
  tag: StudentTag;
}) => {
  const queryClient = useQueryClient();

  const addMessageToCache = useCallback(
    (message: AnnounceMessage) => {
      queryClient.setQueriesData<InfiniteData<AnnounceMessagesResponse>>(
        { queryKey: buildKey(sectionId, tag) },
        (oldData) => {
          if (!oldData) return oldData;

          return {
            ...oldData,
            pages: [
              {
                ...oldData.pages[0],
                data: [message, ...oldData.pages[0].data],
              },
              ...oldData.pages.slice(1),
            ],
          };
        },
      );
    },
    [queryClient, sectionId, tag],
  );

  return {
    addMessageToCache,
  };
};
