import { Button } from "@studyplus/boron-ui";
import { FC } from "react";
import { useCallback } from "react";
import { Modal } from "../../../components/atoms/Modal";
import DeprecatedTagButton from "../../../components/features/DeprecatedTagButton";
import { AnnounceDestinationTagWithFormStateInterface } from "../../../interfaces/SectionAnnounceMessagesStateInterface";
import { DestinationOption } from "./DestinationOption";
import styles from "./styles.scss";
import { useDestinationTagState } from "./useDestinationTagState";

interface DestinationModalProps {
  sectionId: string;
  isOpen: boolean;
  tags: AnnounceDestinationTagWithFormStateInterface[];
  onClose: () => void;
  onDecideTag: (tag: AnnounceDestinationTagWithFormStateInterface) => void;
}

export const DestinationModal: FC<DestinationModalProps> = ({
  sectionId,
  isOpen,
  tags,
  onClose,
  onDecideTag,
}) => {
  const { selectedTag, openedTag, handleTagSelection, handleTagOpen } =
    useDestinationTagState();
  const studentCount = selectedTag ? selectedTag.studentCount : 0;

  const handleDeselect = useCallback(
    () => handleTagSelection(null),
    [handleTagSelection],
  );
  const handleDecide = useCallback(() => {
    if (selectedTag) {
      onDecideTag(selectedTag);
      onClose();
    }
  }, [selectedTag, onDecideTag, onClose]);

  const handleSelect = useCallback(
    (tag: AnnounceDestinationTagWithFormStateInterface) => () =>
      handleTagSelection(tag),
    [handleTagSelection],
  );

  const handleOpen = useCallback(
    (tag: AnnounceDestinationTagWithFormStateInterface) => () =>
      handleTagOpen(tag),
    [handleTagOpen],
  );

  const handleClose = useCallback(() => handleTagOpen(null), [handleTagOpen]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Header onClose={onClose}>
        <h2>宛先選択</h2>
      </Modal.Header>
      <Modal.Body>
        <h3 className={styles.heading}>送信先（{studentCount}名）：</h3>
        <div className={styles.row}>
          <div className={styles.selectedTag}>
            {selectedTag ? (
              <DeprecatedTagButton
                label={selectedTag.name}
                iconName="icon-close-x"
                theme={
                  selectedTag.type === "smart_tag" ? "smarttag-white" : "white"
                }
                onClick={handleDeselect}
                dataTestId={
                  "announce-message-destination-modal-option-delete-button"
                }
              />
            ) : null}
          </div>
          <Button
            type="button"
            disabled={!selectedTag}
            onClick={handleDecide}
            className={styles.button}
          >
            決定
          </Button>
        </div>
        <div>
          {tags
            .filter((tag) => tag.enabled)
            .map((destinationTag) => (
              <DestinationOption
                key={destinationTag.id}
                sectionId={sectionId}
                destinationTag={destinationTag}
                isOpened={!!openedTag && openedTag.id === destinationTag.id}
                isSelected={
                  !!selectedTag && selectedTag.id === destinationTag.id
                }
                isDisabled={!destinationTag.studentCount}
                onSelect={handleSelect}
                onOpen={handleOpen}
                onClose={handleClose}
              />
            ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};
