import { useFormik } from "formik";
import * as React from "react";
import * as yup from "yup";
import {
  Content,
  FileTypes,
  formatFileSize,
  isValidFilesize,
  nameLengthLimit,
  uploadFileSizeLimit,
} from "../../../domains/Content";
import { ContentMutationParams } from "./useContentFileApi";

type Values = {
  name: string;
  file: File | null;
};

export type UseContentFileFormProps = {
  fetchedContent: Content;
  mutate: (values: ContentMutationParams) => void;
};
export const useContentFileForm = ({
  fetchedContent,
  mutate,
}: UseContentFileFormProps) => {
  const inputFileRef = React.useRef<HTMLInputElement | null>(null);

  const onClickFile = () => {
    inputFileRef.current?.click();
  };

  const initialValues = { name: fetchedContent.name, file: null };
  const formik = useFormik<Values>({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      mutate({
        contentId: fetchedContent.id,
        name: values.name.trim(),
        file: values.file,
      });
    },
  });

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      formik.setFieldTouched("name", true);
      formik.setFieldValue("name", file.name);
    }
    formik.setFieldTouched("file", true);
    formik.setFieldValue("file", file);
  };

  return {
    inputFileRef,
    formik,
    onClickFile,
    onChangeFile,
    acceptTypes: FileTypes.join(", "),
  };
};

const ERROR_STR_FILE_SIZE = `アップロードできる容量は${formatFileSize(
  uploadFileSizeLimit,
)}までです`;
export const ERROR_STR_CONTENT_NAME = `コンテンツ名は${nameLengthLimit}文字以内にしてください`;

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("コンテンツ名の入力は必須です")
    .max(nameLengthLimit, ERROR_STR_CONTENT_NAME),
  file: yup
    .mixed<File>()
    .test("fileSize", ERROR_STR_FILE_SIZE, (file) =>
      file ? isValidFilesize(file.size) : true,
    ),
});
