import { FC } from "react";
import Icon from "../../../components/atoms/Icon";
import Loader from "../../../components/atoms/Loader";
import { AnnounceMessageRecipientsWrapper } from "../../../components/features/AnnounceMessageRecipientsWrapper";
import { useQueryError } from "../../../hooks/http/useQueryError.ts";
import { AnnounceDestinationTagWithFormStateInterface } from "../../../interfaces/SectionAnnounceMessagesStateInterface";
import { useFetchDestinationTagRecipients } from "../useFetchDestinationTagRecipients.ts";
import styles from "./styles.scss";

interface RecipientsProps {
  sectionId: string;
  tag: AnnounceDestinationTagWithFormStateInterface;
}

export const Recipients: FC<RecipientsProps> = ({ sectionId, tag }) => {
  const {
    recipients,
    fetchNextPage,
    hasNextPage,
    isPending,
    isFetchingNextPage,
    error,
  } = useFetchDestinationTagRecipients({ sectionId, destinationTag: tag });
  useQueryError(error);

  const handleLoadMore = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  return (
    <AnnounceMessageRecipientsWrapper onReachToBottom={handleLoadMore}>
      {recipients?.map((recipient) => (
        <div
          key={`announce-message-recipient-${recipient.id}`}
          className={styles.recipient}
        >
          <Icon name="icon-navi-avatar" className={styles.recipient__icon} />
          <div className={styles.recipient__name}>{recipient.fullName}</div>
        </div>
      ))}
      <Loader loading={isPending || isFetchingNextPage} />
    </AnnounceMessageRecipientsWrapper>
  );
};
