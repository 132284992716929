import { Fragment } from "react";
import { Link } from "react-router-dom";

import StudentTag from "../../../domains/StudentTag";
import PathHelper from "../../../helpers/PathHelper";
import SectionInterface from "../../../interfaces/SectionInterface";
import styles from "./styles.scss";

interface TagNameProps {
  section: SectionInterface;
  tag: StudentTag | null;
}

export const TagName: React.FC<TagNameProps> = ({ section, tag }) => {
  if (tag) {
    return (
      <Fragment>
        <Link
          to={PathHelper.getStudentsPath(section.id, tag)}
          className={styles.header__link}
        >
          {tag.name}
        </Link>
        へメッセージ
      </Fragment>
    );
  } else {
    return <>新しいメッセージを作成</>;
  }
};
