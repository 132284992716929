import { FC, useEffect, useRef } from "react";
import { UIEvent } from "react";
import Dropdown from "../../../components/atoms/Dropdown";
import Icon from "../../../components/atoms/Icon";
import Loader from "../../../components/atoms/Loader";
import { useQueryError } from "../../../hooks/http/useQueryError.ts";
import { AnnounceDestinationTagWithFormStateInterface } from "../../../interfaces/SectionAnnounceMessagesStateInterface";
import { useFetchDestinationTagRecipients } from "../useFetchDestinationTagRecipients.ts";
import styles from "./styles.scss";

interface Props {
  sectionId: string;
  destinationTag: AnnounceDestinationTagWithFormStateInterface | null;
}

export const DestinationRecipients: FC<Props> = (props) => {
  const { sectionId, destinationTag } = props;

  const {
    recipients,
    isPending,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    error,
  } = useFetchDestinationTagRecipients({
    sectionId,
    destinationTag,
  });
  useQueryError(error);

  const handleLoadMore = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  const { handleRecipientsScroll, scrollRef } = useDestinationRecipients({
    handleLoadMore,
  });

  if (!destinationTag || destinationTag.studentCount === 0) {
    return (
      <span
        className={`${styles.destinationLabel} ${styles.destinationLabelNoRecipients}`}
      >
        送信先：（0名）
        <Icon
          name="icon-arrow-down"
          className={styles.destinationLabel__icon}
        />
      </span>
    );
  }

  return (
    <Dropdown.Container>
      <Dropdown.Label>
        <span className={styles.destinationLabel}>
          送信先（{destinationTag.studentCount}名）
          <Icon
            name="icon-arrow-down"
            className={styles.destinationLabel__icon}
          />
        </span>
      </Dropdown.Label>
      <Dropdown.List position={{ bottom: "2rem", left: "0" }}>
        <div
          className={styles.recipientsContainer}
          onScroll={handleRecipientsScroll}
          ref={scrollRef}
        >
          <ul className={styles.recipients}>
            {recipients?.map((recipient) => (
              <li
                key={`AnnounceMessageform-destinationRecipients-${recipient.id}`}
              >
                {recipient.fullName}
              </li>
            ))}
            <Loader loading={isPending || isFetchingNextPage} />
          </ul>
        </div>
      </Dropdown.List>
    </Dropdown.Container>
  );
};

const useDestinationRecipients = ({
  handleLoadMore,
}: {
  handleLoadMore: () => void;
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const handleRecipientsScroll = (e: UIEvent<HTMLDivElement>) => {
    if ((e.target as Element).scrollTop === 0) {
      handleLoadMore();
    }
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, []);

  return {
    handleRecipientsScroll,
    scrollRef,
  };
};
