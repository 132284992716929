import {
  InfiniteData,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { boronClient } from "../../api";
import StudentTag from "../../domains/StudentTag";
import { createError } from "../../errors";
import { getNextPageParam } from "../../helpers/ReactQueryHelper";
import AnnounceDestinationTagInterface from "../../interfaces/AnnounceDestinationTagInterface";
import { AnnounceMessage } from "../../interfaces/MessageInterface";
import { paths } from "../../lib/api/v1";

type DestinationTagsResponse =
  paths["/api/v1/sections/{section_id}/all_tags"]["get"]["responses"]["200"]["content"]["application/json"]["allTags"];

const buildKey = (sectionId: string) => [
  "/api/v1/sections/{section_id}/all_tags",
  sectionId,
  {
    with_latest_message: true,
    active_student: true,
  },
];

export const useFetchDestinationTags = ({
  sectionId,
}: {
  sectionId: string;
}) => {
  const result = useInfiniteQuery<DestinationTagsResponse>({
    queryKey: buildKey(sectionId),
    queryFn: async ({ pageParam }) => {
      const { response, data } = await boronClient.GET(
        "/api/v1/sections/{section_id}/all_tags",
        {
          params: {
            path: { section_id: sectionId },
            query: {
              page: pageParam as number,
              with_latest_message: true,
              active_student: true,
            },
          },
        },
      );
      if (response.ok && data) {
        return data.allTags;
      }

      throw await createError(response);
    },
    initialPageParam: 1,
    getNextPageParam,
  });

  const destinationTags = useMemo(
    () =>
      (result.data?.pages.flatMap((page) => page.data) || null) as
        | AnnounceDestinationTagInterface[] // with_latest_messageがtrueの場合はAnnounceDestinationTagInterface[]と同等になる
        | null,
    [result.data],
  );

  const loadMore = () => {
    if (!result.isFetchingNextPage && result.hasNextPage) {
      result.fetchNextPage();
    }
  };

  return {
    ...result,
    destinationTags,
    loadMore,
  };
};

export const useUpdateDestinationTagLatestMessageCache = ({
  sectionId,
  tag,
}: {
  sectionId: string;
  tag: StudentTag;
}) => {
  const queryClient = useQueryClient();

  const updateDestinationTagLatestMessageCache = useCallback(
    (message: AnnounceMessage) => {
      queryClient.setQueriesData<InfiniteData<DestinationTagsResponse>>(
        { queryKey: buildKey(sectionId) },
        (oldData) => {
          if (!oldData) return oldData;

          return {
            ...oldData,
            pages: oldData.pages.map((page) => ({
              ...page,
              data: page.data.map((destinationTag) =>
                destinationTag.id === tag.id && destinationTag.type === tag.type
                  ? { ...destinationTag, latestMessage: message }
                  : destinationTag,
              ),
            })),
          };
        },
      );
    },
    [queryClient, sectionId, tag],
  );

  return {
    updateDestinationTagLatestMessageCache,
  };
};
