import { useLayoutEffect, useRef } from "react";
import { AnnounceMessage } from "../../../interfaces/MessageInterface";

export const useAnnounceMessageScroll = ({
  messages,
  loadMore,
}: {
  messages: AnnounceMessage[] | null;
  loadMore: () => void;
}) => {
  const scroller = useRef<HTMLDivElement | null>(null);
  const scrollerHeight = useRef<number>(0);
  const loadedMessages = useRef<typeof messages>(null);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (e.currentTarget.scrollTop === 0) {
      loadMore();
    }
  };

  useLayoutEffect(() => {
    const initializing = loadedMessages.current === null;
    if (scroller.current && initializing) {
      scroller.current.scrollTop = scroller.current.scrollHeight;
    }
    if (scroller.current && !initializing) {
      if (
        loadedMessages.current &&
        loadedMessages.current[0] &&
        messages &&
        messages[0] &&
        loadedMessages.current[0].id === messages[0].id
      ) {
        // 追加読込を行った時
        scroller.current.scrollTop =
          scroller.current.scrollHeight - scrollerHeight.current;
      } else {
        // 新しいメッセージを送信した時
        scroller.current.scrollTop = scroller.current.scrollHeight;
      }
    }
    if (scroller.current) {
      scrollerHeight.current = scroller.current.scrollHeight;
    }
    loadedMessages.current = messages;
  }, [messages?.map((message) => message.id).join(",")]);

  return {
    scroller,
    handleScroll,
  };
};
