import { useMemo } from "react";
import Loader from "../../../components/atoms/Loader";
import DeprecatedTagButton from "../../../components/features/DeprecatedTagButton";
import StudentTag, { StudentTagType } from "../../../domains/StudentTag";
import { useFetchStudentTags } from "../../../hooks/http/useFetchStudentTags";
import { useQueryError } from "../../../hooks/http/useQueryError";
import { AnnounceDestinationTagWithFormStateInterface } from "../../../interfaces/SectionAnnounceMessagesStateInterface";
import { DestinationModal } from "./DestinationModal";

interface Props {
  sectionId: string;
  destinationTag: AnnounceDestinationTagWithFormStateInterface | null;
  isModalOpen: boolean;
  onDecideTag: (tag: AnnounceDestinationTagWithFormStateInterface) => void;
  onDeselectTag: () => void;
  onCloseModal: () => void;
  onOpenModal: () => void;
}

const AnnounceDestinationSelector: React.FC<Props> = ({
  sectionId,
  destinationTag,
  isModalOpen,
  onDecideTag,
  onDeselectTag,
  onCloseModal,
  onOpenModal,
}) => {
  const { data, error } = useFetchStudentTags({
    sectionId,
    activeStudent: true,
  });
  useQueryError(error);
  const fetchedTags = data?.tags;

  const tags = useMemo(() => {
    if (!fetchedTags) {
      return null;
    }

    return [
      ...sortAndFilterByName(fetchedTags, "status"),
      ...sortAndFilterByName(fetchedTags, "job"),
      ...sortAndFilterByName(fetchedTags, "tag_ids"),
      ...sortAndFilterByName(fetchedTags, "smart_tag"),
    ];
  }, [fetchedTags?.map((tag) => tag.id).join(",")]);

  if (!tags) {
    return <Loader loading={true} />;
  }

  if (destinationTag) {
    return (
      <DeprecatedTagButton
        label={destinationTag.name}
        theme={destinationTag.type === "smart_tag" ? "smarttag-white" : "white"}
        iconName={"icon-close-x"}
        onClick={onDeselectTag}
        dataTestId={"announce-message-form-destination-delete-button"}
      />
    );
  }

  return (
    <>
      <DeprecatedTagButton
        label="選択"
        iconName="icon-plus-tag"
        theme="main"
        onClick={onOpenModal}
      />
      {tags && (
        <DestinationModal
          sectionId={sectionId}
          isOpen={isModalOpen}
          tags={tags}
          onClose={onCloseModal}
          onDecideTag={onDecideTag}
        />
      )}
    </>
  );
};

const sortAndFilterByName = (
  tags: AnnounceDestinationTagWithFormStateInterface[],
  type: StudentTagType,
) =>
  tags
    .filter((tag) => tag.type === type)
    .sort((a: StudentTag, b: StudentTag) => (a.name > b.name ? 1 : -1));

export default AnnounceDestinationSelector;
