import * as React from "react";
import { useMemo } from "react";
import AnnounceMessageRecipientsModal from "../../../../components/features/AnnounceMessageRecipientsModal";
import { useQueryError } from "../../../../hooks/http/useQueryError";
import { GuardianAnnounceMessageTagInterface } from "../../../../interfaces/GuardianAnnounceMessageTagInterface";
import styles from "./styles.scss";
import { useFetchRecipients } from "./useFetchRecipients";

export const DestinationLabel = (props: {
  sectionId: string;
  destinationTag: GuardianAnnounceMessageTagInterface;
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const { isLoading, data, fetchNextPage, error, hasNextPage } =
    useFetchRecipients({
      sectionId: props.sectionId,
      tag: props.destinationTag,
    });
  useQueryError(error);

  const recipients = useMemo(() => {
    return data?.pages.flatMap((page) => page.data) ?? [];
  }, [data]);

  return (
    <>
      <span
        className={`${styles.destinationLabel} ${styles.destinationLabel__link}`}
        onClick={handleOpenModal}
      >
        保護者送信先：{props.destinationTag.studentCount}名
      </span>
      <AnnounceMessageRecipientsModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        destinationTag={props.destinationTag}
        recipients={recipients}
        totalCount={props.destinationTag.studentCount}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        loadMore={fetchNextPage}
      />
    </>
  );
};
