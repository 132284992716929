import * as React from "react";
import { useNavigate } from "react-router-dom";
import { boronClient, makeFormData } from "../../../api.ts";
import StudentTag from "../../../domains/StudentTag";
import { UnprocessableEntityError } from "../../../errors.ts";
import compressImage from "../../../helpers/compressImage.ts";
import { useBoronMutation } from "../../../hooks/http/useBoronMutation.ts";
import { useFetchProfile } from "../../../hooks/http/useFetchProfile.ts";
import { useFlashMessage } from "../../../hooks/useFlashMessage.ts";
import { ApiErrorInterface } from "../../../interfaces/ApiErrorResponseInterface.ts";
import { MessageFormValuesInterface } from "../../../interfaces/MessageInterface";
import { PostZoomMeetingMessageParams } from "../../../interfaces/PostZoomMeetingMessageParams";
import { AnnounceDestinationTagWithFormStateInterface } from "../../../interfaces/SectionAnnounceMessagesStateInterface.ts";
import SectionInterface from "../../../interfaces/SectionInterface.ts";
import { useAddAnnounceMessageToCache } from "../../SectionsAnnounceMessagesPage/useFetchAnnounceMessages.ts";
import { useUpdateDestinationTagLatestMessageCache } from "../../SectionsAnnounceMessagesPage/useFetchDestinationTags.ts";

const initialValues = { content: "", file: null };

const compressFile = async (file: File): Promise<File> => {
  const compressedFile =
    file.type === "application/pdf" ? file : await compressImage(file);
  return compressedFile;
};

export const useAnnounceMessageForm = ({
  section,
  destinationTag,
}: {
  section: SectionInterface;
  destinationTag: AnnounceDestinationTagWithFormStateInterface | null;
}) => {
  const [values, setValues] =
    React.useState<MessageFormValuesInterface>(initialValues);
  const [apiErrors, setApiErrors] = React.useState<ApiErrorInterface[]>([]);

  const { showErrorMessage, showSuccessMessage } = useFlashMessage();

  const dummyTag = {
    id: "",
    type: "tag_ids" as const,
    name: "",
    enabled: false,
  };

  const { addMessageToCache } = useAddAnnounceMessageToCache({
    sectionId: section.id,
    tag: destinationTag ?? dummyTag,
  });

  const { updateDestinationTagLatestMessageCache } =
    useUpdateDestinationTagLatestMessageCache({
      sectionId: section.id,
      tag: destinationTag ?? dummyTag,
    });

  const {
    mutate: postAnnounceMessage,
    isPending: isPostAnnounceMessagePending,
  } = useBoronMutation(
    async (content: string) =>
      await boronClient.POST(
        "/api/v1/sections/{section_id}/announce_messages",
        {
          params: {
            path: { section_id: section.id },
          },
          body: {
            destination_id: destinationTag?.id ?? "",
            type: destinationTag?.type ?? "tag_ids",
            content,
          },
        },
      ),
    {
      onSuccess: ({ message }) => {
        setValues(initialValues);
        setApiErrors([]);
        showSuccessMessage("メッセージを送信しました");
        if (destinationTag) {
          addMessageToCache(message);
          updateDestinationTagLatestMessageCache(message);
        }
      },
      onError: () => {
        showErrorMessage("メッセージが送信できませんでした");
      },
    },
  );

  const { mutate: postFile, isPending: isPostFilePending } = useBoronMutation(
    async (file: File) => {
      const compressedFile = await compressFile(file);
      const body =
        file.type === "application/pdf"
          ? { file: compressedFile }
          : { image: compressedFile };

      return await boronClient.POST(
        "/api/v1/sections/{section_id}/announce_messages",
        {
          params: {
            path: { section_id: section.id },
          },
          body: {
            destination_id: destinationTag?.id ?? "",
            type: destinationTag?.type ?? "tag_ids",
            ...body,
          },
          bodySerializer: makeFormData,
        },
      );
    },
    {
      onSuccess: ({ message }) => {
        setValues(initialValues);
        setApiErrors([]);
        showSuccessMessage("メッセージを送信しました");
        if (destinationTag) {
          addMessageToCache(message);
          updateDestinationTagLatestMessageCache(message);
        }
      },
      onError: (error) => {
        showErrorMessage("メッセージが送信できませんでした");
        if (error instanceof UnprocessableEntityError) {
          setApiErrors(error.originalErrors);
        }
      },
    },
  );

  const {
    mutate: postZoomMeetingMessage,
    isPending: isPostZoomMeetingMessagePending,
  } = useBoronMutation(
    async ({
      zoomParams,
    }: {
      zoomParams: PostZoomMeetingMessageParams;
    }) =>
      await boronClient.POST(
        "/api/v1/sections/{section_id}/announce_messages/zoom_meetings",
        {
          params: {
            path: { section_id: section.id },
          },
          body: {
            destination_id: destinationTag?.id ?? "",
            type: destinationTag?.type ?? "tag_ids",
            ...zoomParams,
          },
        },
      ),
    {
      onSuccess: ({ message }) => {
        setValues(initialValues);
        setApiErrors([]);
        showSuccessMessage("メッセージを送信しました");
        if (destinationTag) {
          addMessageToCache(message);
          updateDestinationTagLatestMessageCache(message);
        }
      },
      onError: () => {
        showErrorMessage("メッセージを送信できませんでした");
      },
    },
  );

  React.useEffect(() => {
    setValues(initialValues);
  }, [section.id, destinationTag?.id]);

  // テキストをフォームに設定
  const handleChangeContent = (content: string) => {
    if (!destinationTag) {
      return;
    }

    setValues((prevValues) => ({ ...prevValues, content }));
  };

  // ファイルをフォームに設定
  const handleChangeFormFile = (file: File | null) => {
    if (!destinationTag) {
      return;
    }

    setValues((prevValues) => ({ ...prevValues, file }));
  };

  const handleSubmitContent = (content: string) => {
    if (!destinationTag) {
      return;
    }

    postAnnounceMessage(content);
  };

  const handleSubmitFile = (file: File) => {
    if (!destinationTag) {
      return;
    }

    postFile(file);
  };

  const handlePostZoomMeetingMessage = (
    zoomParams: PostZoomMeetingMessageParams,
    setSubmitting: (submitting: boolean) => void,
    setErrors: (errors: Record<string, unknown>) => void,
    onSuccessCallback: () => void,
  ) => {
    if (!destinationTag) {
      return;
    }

    setSubmitting(true);
    postZoomMeetingMessage(
      {
        zoomParams,
      },
      {
        onSuccess: () => {
          onSuccessCallback();
        },
        onError: (error) => {
          if (error instanceof UnprocessableEntityError) {
            const errors = error.originalErrors.reduce<Record<string, unknown>>(
              (p, c) => {
                if (!c.field) return p;
                return { ...p, [c.field]: c.message };
              },
              {},
            );
            setErrors(errors);
          }
        },
        onSettled: () => {
          setSubmitting(false);
        },
      },
    );
  };

  const isSkillEnabled = (): boolean => {
    return !!destinationTag && !!destinationTag.enabled;
  };

  const hasRecipient = (): boolean => {
    if (!destinationTag || !destinationTag.studentCount) {
      return false;
    }

    return destinationTag.studentCount > 0;
  };

  const disabledPlaceholder = (): string => {
    if (!destinationTag) {
      return "送信先を選択してください。";
    }

    if (!isSkillEnabled()) {
      return "対象のアシスタントスキルがオフになっているため送信出来ません。";
    }

    return "指定した送信対象が0件です。送信対象が存在するタグを指定して下さい。";
  };

  const navigate = useNavigate();

  const handleSelectDestinationTag = (tag: StudentTag) => {
    navigate(`/sections/${section.id}/tags/${tag.id}/messages`);
  };

  const handleDeselectDestinationTag = () => {
    navigate(`/sections/${section.id}/tags/messages/new`);
  };

  const { data: operatorProfile } = useFetchProfile();

  const formState = {
    submitting:
      isPostAnnounceMessagePending ||
      isPostFilePending ||
      isPostZoomMeetingMessagePending,
    apiErrors,
    values,
  };

  return {
    formState,
    handleChangeContent,
    handleChangeFormFile,
    handleSubmitContent,
    handleSubmitFile,
    handlePostZoomMeetingMessage,
    handleSelectDestinationTag,
    handleDeselectDestinationTag,
    disabledPlaceholder,
    hasRecipient,
    operatorProfile,
  };
};
