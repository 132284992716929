import { FC } from "react";
import { generateStorageKey } from "../../../components/atoms/ResumableTextarea/useResumableTextarea";
import CommonMessageForm from "../../../components/molecules/CommonMessageForm";
import PathHelper from "../../../helpers/PathHelper";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import { AnnounceDestinationTagWithFormStateInterface } from "../../../interfaces/SectionAnnounceMessagesStateInterface";
import SectionInterface from "../../../interfaces/SectionInterface";
import AnnounceDestinationSelector from "../AnnounceMessageDestinationSelector";
import { DestinationRecipients } from "./DestinationRecipients";
import styles from "./styles.scss";
import { useAnnounceMessageForm } from "./useAnnounceMessageForm";

interface Props {
  section: SectionInterface;
  destinationTag: AnnounceDestinationTagWithFormStateInterface | null;
  isDestinationTagModalOpen: boolean;
  onOpenDestinationTagModal: () => void;
  onCloseDestinationTagModal: () => void;
  currentOperator: OperatorInterface;
}

const AnnounceMessageForm: FC<Props> = (props) => {
  const {
    formState,
    handleChangeContent,
    handleChangeFormFile,
    handleSubmitContent,
    handleSubmitFile,
    handlePostZoomMeetingMessage,
    handleSelectDestinationTag,
    handleDeselectDestinationTag,
    hasRecipient,
    disabledPlaceholder,
    operatorProfile,
  } = useAnnounceMessageForm({
    section: props.section,
    destinationTag: props.destinationTag,
  });

  return (
    <div className={styles.root}>
      <div className={styles.control}>
        <DestinationRecipients
          sectionId={props.section.id}
          destinationTag={props.destinationTag}
        />
        <div className={styles.selector}>
          <AnnounceDestinationSelector
            sectionId={props.section.id}
            destinationTag={props.destinationTag}
            onDecideTag={handleSelectDestinationTag}
            onDeselectTag={handleDeselectDestinationTag}
            isModalOpen={props.isDestinationTagModalOpen}
            onCloseModal={props.onCloseDestinationTagModal}
            onOpenModal={props.onOpenDestinationTagModal}
          />
        </div>
      </div>

      <CommonMessageForm
        formState={formState}
        canSendMessage={hasRecipient()}
        allowedFileTypes={["jpg", "gif", "png", "pdf"]}
        disabledPlaceholder={disabledPlaceholder()}
        postMessage={handleSubmitContent}
        postMessageFile={handleSubmitFile}
        changeFormContent={handleChangeContent}
        changeFormFile={handleChangeFormFile}
        rows={3}
        destination={
          props.destinationTag
            ? {
                path: PathHelper.getStudentsPath(
                  props.section.id,
                  props.destinationTag,
                ),
                name: props.destinationTag.name,
              }
            : null
        }
        operator={props.currentOperator}
        operatorProfile={operatorProfile}
        postZoomMeetingMessage={handlePostZoomMeetingMessage}
        defaultZoomMeetingContent={`Zoomミーティングを行います。`}
        storageKey={generateStorageKey([
          "messages",
          props.section.id,
          props.destinationTag?.id || "null",
          "new",
        ])}
      />
    </div>
  );
};

export default AnnounceMessageForm;
