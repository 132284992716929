import { useInfiniteQuery } from "@tanstack/react-query";
import { boronClient } from "../../api";
import { HTTPErrors, createError } from "../../errors";
import { getNextPageParam } from "../../helpers/ReactQueryHelper";
import ApiResponse from "../../interfaces/ApiResponse";
import { Recipient } from "../../interfaces/SectionAnnounceMessagesStateInterface";

export const useFetchAnnounceMessageRecipients = ({
  sectionId,
  announceMessageId,
}: {
  sectionId: string;
  announceMessageId: string;
}) => {
  const result = useInfiniteQuery<ApiResponse<Recipient[]>, HTTPErrors>({
    queryKey: [
      "/api/v1/sections/{section_id}/announce_messages/{announce_message_hashid}/recipient_students",
      sectionId,
      announceMessageId,
    ],
    queryFn: async ({ pageParam }) => {
      const { response, data } = await boronClient.GET(
        "/api/v1/sections/{section_id}/announce_messages/{announce_message_hashid}/recipient_students",
        {
          params: {
            path: {
              section_id: sectionId,
              announce_message_hashid: announceMessageId,
            },
            query: {
              page: pageParam as number,
            },
          },
        },
      );

      if (response.ok && data) {
        return data.recipientStudents;
      }

      throw await createError(response);
    },
    getNextPageParam,
    initialPageParam: 1,
  });

  const recipients = result.data?.pages.flatMap((page) => page.data);

  const loadMore = () => {
    if (result.hasNextPage && !result.isFetchingNextPage) {
      result.fetchNextPage();
    }
  };

  return {
    ...result,
    recipients,
    loadMore,
  };
};
