import { useCallback, useState } from "react";
import { AnnounceDestinationTagWithFormStateInterface } from "../../../interfaces/SectionAnnounceMessagesStateInterface";

export const useDestinationTagState = () => {
  const [selectedTag, setSelectedTag] =
    useState<AnnounceDestinationTagWithFormStateInterface | null>(null);
  const [openedTag, setOpenedTag] =
    useState<AnnounceDestinationTagWithFormStateInterface | null>(null);

  const handleTagSelection = useCallback(
    (tag: AnnounceDestinationTagWithFormStateInterface | null) => {
      if (tag && !tag.studentCount) {
        return;
      }
      setSelectedTag(tag);
    },
    [],
  );

  const handleTagOpen = useCallback(
    (tag: AnnounceDestinationTagWithFormStateInterface | null) => {
      setOpenedTag(tag);
    },
    [],
  );

  return {
    selectedTag,
    openedTag,
    handleTagSelection,
    handleTagOpen,
  };
};
