import { useEffect } from "react";
import Loader from "../../components/atoms/Loader";
import {
  OutletContextProps,
  WithRouterProps,
  withRouter,
} from "../../helpers/RouterHelper";
import enhanceSectionsPage from "../../hocs/enhanceSectionsPage";
import { useFetchStudentTags } from "../../hooks/http/useFetchStudentTags";
import { useQueryError } from "../../hooks/http/useQueryError";
import OperatorInterface from "../../interfaces/OperatorInterface";
import OperatorPrivilegesStateInterface from "../../interfaces/OperatorPrivilegesStateInterface";
import SectionInterface from "../../interfaces/SectionInterface";
import SectionAnnounceMessages from "./SectionAnnounceMessages";
import { useFetchDestinationTags } from "./useFetchDestinationTags";

interface StateProps {
  section: SectionInterface;
  operatorPrivileges: OperatorPrivilegesStateInterface;
  currentOperator: OperatorInterface;
}

export type Params =
  | { sectionId: string }
  | { sectionId: string; action: string }
  | { sectionId: string; destinationTagId: string };

interface Props
  extends OutletContextProps,
    WithRouterProps<Params>,
    StateProps {}

const SectionsAnnounceDestinationTagsPage: React.FC<Props> = ({
  section,
  operatorPrivileges,
  currentOperator,
  params,
  setActiveMenu,
}) => {
  useEffect(() => {
    setActiveMenu("message");
  }, []);

  const destinationTagsQuery = useFetchDestinationTags({
    sectionId: params.sectionId,
  });
  useQueryError(destinationTagsQuery.error);
  const allTagsQuery = useFetchStudentTags({
    sectionId: section.id,
    activeStudent: true,
  });
  useQueryError(allTagsQuery.error);

  if (destinationTagsQuery.isPending || allTagsQuery.isPending) {
    return <Loader loading />;
  }

  return (
    <SectionAnnounceMessages
      section={section}
      destinationTags={destinationTagsQuery.destinationTags ?? []}
      isLoading={destinationTagsQuery.isPending}
      isFetchingNextPage={destinationTagsQuery.isFetchingNextPage}
      operatorPrivilege={operatorPrivileges.data}
      onLoadMore={destinationTagsQuery.loadMore}
      currentOperator={currentOperator}
      params={params}
      allTags={allTagsQuery.data?.tags ?? []}
    />
  );
};

const pageInfo = {
  title: "メッセージ",
};

const EnhancedPage = enhanceSectionsPage(
  SectionsAnnounceDestinationTagsPage,
  pageInfo,
  ["announce_message:index"],
);

export default withRouter<Props>(EnhancedPage);
