import { Fragment } from "react";
import Icon from "../../../components/atoms/Icon";
import SplitLayout from "../../../components/atoms/SplitLayout";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import { AnnounceDestinationTagWithFormStateInterface } from "../../../interfaces/SectionAnnounceMessagesStateInterface";
import SectionInterface from "../../../interfaces/SectionInterface";
import AnnounceMessageForm from "../AnnounceMessageForm";
import AnnounceMessageList from "./AnnounceMessageList";
import { TagName } from "./TagName";
import styles from "./styles.scss";

interface Props {
  destinationTag: AnnounceDestinationTagWithFormStateInterface | null;
  onCloseRightColumn: () => void;
  section: SectionInterface;
  isDestinationTagModalOpen: boolean;
  onOpenDestinationTagModal: () => void;
  onCloseDestinationTagModal: () => void;
  currentOperator: OperatorInterface;
}

const AnnounceMessage: React.FC<Props> = (props) => {
  return (
    <Fragment>
      <SplitLayout.Header>
        <Icon
          name="icon-arrow-close"
          className={styles.close}
          onClick={props.onCloseRightColumn}
        />
        <span className={styles.header}>
          <TagName section={props.section} tag={props.destinationTag} />
        </span>
      </SplitLayout.Header>
      <SplitLayout.Body>
        {props.destinationTag ? (
          <AnnounceMessageList
            destinationTag={props.destinationTag}
            section={props.section}
          />
        ) : (
          <div className={styles.listContainer} />
        )}
        <AnnounceMessageForm
          destinationTag={props.destinationTag}
          section={props.section}
          isDestinationTagModalOpen={props.isDestinationTagModalOpen}
          onOpenDestinationTagModal={props.onOpenDestinationTagModal}
          onCloseDestinationTagModal={props.onCloseDestinationTagModal}
          currentOperator={props.currentOperator}
        />
      </SplitLayout.Body>
    </Fragment>
  );
};

export default AnnounceMessage;
