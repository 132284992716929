import classNames from "classnames";
import { Link } from "react-router-dom";

import CircleIcon from "../../../components/atoms/CircleIcon";
import Icon from "../../../components/atoms/Icon";
import TimeHelper from "../../../helpers/TimeHelper";
import AnnounceDestinationTagInterface from "../../../interfaces/AnnounceDestinationTagInterface";
import styles from "./styles.scss";

interface Props {
  destinationTag: AnnounceDestinationTagInterface;
  isActive: boolean;
  sectionId: string;
  onClickTag: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

const AnnounceDestinationTagListItem = (props: Props) => {
  const { name, type } = props.destinationTag;

  return (
    <Link
      to={`/sections/${props.sectionId}/tags/${props.destinationTag.id}/messages`}
      className={classNames(styles.root, {
        [styles.isActive]: props.isActive,
      })}
      onClick={props.onClickTag}
    >
      <div className={styles.left}>
        <CircleIcon
          iconProps={{ name: "icon-all-message", iconClassName: styles.icon }}
          className={styles.iconWrapper}
        />
      </div>
      <div className={styles.right}>
        <div className={styles.rightHeader}>
          <div className={styles.name}>
            {type === "smart_tag" ? (
              <Icon
                name="icon-notice-assistant"
                fileType="png"
                className={styles.assistantIcon}
              />
            ) : null}
            <span className={styles.name__text}>{name}</span>
          </div>
          {renderSentAt(props)}
        </div>
        {renderMessage(props)}
      </div>
    </Link>
  );
};

const renderSentAt = (props: Props) => {
  const { latestMessage } = props.destinationTag;

  if (latestMessage) {
    return (
      <time className={styles.datetime}>
        {TimeHelper.fullFormat(latestMessage.sentAt)}
      </time>
    );
  } else {
    return null;
  }
};

const renderMessage = (props: Props) => {
  const { latestMessage } = props.destinationTag;

  if (latestMessage) {
    if (latestMessage.content) {
      return <p className={styles.content}>{latestMessage.content}</p>;
    } else if (latestMessage.presignedUrl) {
      return <p className={styles.content}>画像ファイル</p>;
    } else {
      return null;
    }
  } else {
    return <p className={styles.content}>まだメッセージがありません</p>;
  }
};

export default AnnounceDestinationTagListItem;
